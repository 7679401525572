import {
	AllComponentTypes,
	generateImageContentUrl,
	getDesignerAlignment,
	getId,
	getPadding,
	getShadowValFromProps,
	getTransformerFormFieldFontStyles,
	slotSubstractor,
} from 'shared/src/utils/shared.js';

import config from '../../../config/config.js';
import { generateButtonClass } from '../landingPage/Button.react';
import { generateCheckboxClass } from '../landingPage/Checkbox.react';
import { generateCheckboxGroupClass } from '../landingPage/CheckboxGroup.react';
import { generateGDPRboxClass } from '../landingPage/GDPRbox.react';
import { generateImageClass } from '../landingPage/Image.react';
import { generateMultiSelectClass } from '../landingPage/MultiSelect.react';
import { generateRadioClass } from '../landingPage/Radio.react';
import { generateRecaptchaClass } from '../landingPage/Recaptcha.react';
import { generateSharedInputClass } from '../landingPage/SharedInput.react';
import { generateSingleSelectClass } from '../landingPage/SingleSelect.react';
import { generateFollowClass } from '../landingPage/SocialFollow.react';
import { generateSpacerClass } from '../landingPage/Spacer.react';
import { generateSummaryPageClass } from '../landingPage/SummaryPage.react';
import { imageUrl } from '../utils.js';

export function generateFileUploadClass(component) {
	const { id, type } = component;

	const uid = getId(id);
	const uniqClass = `${type}${uid}`;

	const wrapperStyle = `
		.${type}-wrapper.${uniqClass} {
			${getPadding(component)}
			background-color: ${component.background_color};
			display: ${(component.hiddenField || component.hidden) && !component.required ? 'none' : 'block'};
		}
	`;

	const dropzoneStyle = `
		.${uniqClass} .${type}-dropzone {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: ${component.fieldPadding}px;
			background-color: ${component.fieldBgColor};
			border: ${component.fieldBorderWidth}px dashed ${component.fieldBorderColour};
			border-radius: ${component.fieldBorderRadius}px;
			cursor: pointer;
			box-shadow: ${getShadowValFromProps(component.box_shadow)};
		}

		.${uniqClass} .${type}-dropzone:has(.${type}-input:focus) {
			outline: 2px solid #007fff;
		}

		.${uniqClass} .${type}-dropzone.has-drag {
			background-color: #f7f6ff;
			border-color: #463abc;
		}

		.${uniqClass} .${type}-dropzone.disabled {
			opacity: 0.5;
		}
	`;

	const dropzoneInputStyle = `
		.${uniqClass} .${type}-input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0;
			cursor: pointer;
		}

		.${uniqClass} .${type}-input:disabled {
			cursor: not-allowed;
		}
	`;

	const dropzoneBoxStyle = `
		.${uniqClass} .${type}-box {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	`;

	const dropzoneImgStyle = `
		.${uniqClass} .${type}-box img {
			max-width: 36px;
			height: auto;
			margin-bottom: 12px;
		}
	`;

	const dropzoneLabelStyle = `
		.${uniqClass} .form-field-label {
			color: ${component.labelFontColour};
			font-size: ${component.labelFontSize / 16}rem;
			font-weight: ${component.labelFontWeight};
			font-style: ${component.labelFontStyle};
			text-decoration: ${component.labelTextDecoration};
			display: ${component.labelHide ? 'none' : 'block'};
			${getTransformerFormFieldFontStyles(component.labelFont)}
		}
	`;

	const dropzoneDescriptionStyle = `
		.${uniqClass} .form-input-description {
			font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
			color: ${component.descriptionFontColour};
			font-family: ${component.descriptionFontFamily};
			font-size: ${component.descriptionFontSize / 16}rem;
			margin-top: ${component.descriptionSpacing}px;
			display: ${component.descriptionHide ? 'none' : 'block'};
		}

		.${uniqClass} .${type}-description-spacer {
			height: 6px;
		}
	`;

	const errorContainerStyle = `
		.${uniqClass} .${type}-error-container {
			display: none;
			margin-top: 8px;
		}

		.${uniqClass} .form-input-error-message {
			position: static;
			margin: 0;
			font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
			font-family: ${component.descriptionFontFamily};
			font-size: ${component.descriptionFontSize / 16}rem;
		}

		.${uniqClass} .form-input-error-message .mdi.mdi-alert-circle {
			visibility: hidden;
		}

		.${uniqClass} .form-input-error-message:first-of-type .mdi.mdi-alert-circle {
			visibility: visible;
		}
	`;

	const fileWrapperStyle = `
		.${uniqClass} .${type}-file-wrapper {
			display: none;
			margin-top: 8px;
		}

		.${uniqClass} .${type}-file-wrapper[style*="block"] ~ .${type}-file-wrapper[style*="block"] {
			margin-top: 4px;
		}
	`;

	const fileStyle = `
		.${uniqClass} .${type}-file {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			background-color: rgba(0, 0, 0, 0.03);
			border-radius: 4px;
		}
	`;

	const fileImgStyle = `
		.${uniqClass} .${type}-file > img {
			max-width: 18px;
			margin-right: 12px;
		}
	`;

	const fileNameStyle = `
		.${uniqClass} .${type}-file-name {
			margin-right: 8px;
			color: #404040;
			font-size: 1rem;
			${getTransformerFormFieldFontStyles(component.labelFont)}
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	`;

	const fileSizeStyleStyle = `
		.${uniqClass} .${type}-file-size {
			margin-right: 8px;
			color: rgba(0, 0, 0, 0.55);
			font-size: 0.875rem;
			${getTransformerFormFieldFontStyles(component.labelFont)}
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.${uniqClass} .${type}-file-size:before {
			content: '(';
		}

		.${uniqClass} .${type}-file-size:after {
			content: ')';
		}
	`;

	const fileActionsStyle = `
		.${uniqClass} .${type}-file-actions {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	`;

	const fileRemoveStyle = `
		.${uniqClass} .${type}-remove {
			width: 22px;
			height: 22px;
			background-color: transparent;
			background-image: url(${imageUrl('/images/file_upload/minus_circle_outline.png')});
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			border: 0;
			padding: 0;
			cursor: pointer;
		}

		.${uniqClass} .${type}-file-wrapper.loading .${type}-remove {
			display: none;
		}
	`;

	const loaderStyle = `
		.${uniqClass} .${type}-loader {
			display: none;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			border: 2px solid transparent;
			border-top-color: rgba(0, 0, 0, 0.94);
			border-right-color: rgba(0, 0, 0, 0.94);
		}

		.${uniqClass} .${type}-file-wrapper.loading .${type}-loader {
			display: block;
			animation: ${type}-loader 1s infinite linear;
		}

		@keyframes ${type}-loader {
			to {
				transform: rotate(360deg);
			}
		}
	`;

	const asteriskStyle = `
		.${uniqClass} .form-field-required {
			color: ${component.asteriskColor};
		}
	`;

	return [
		wrapperStyle,
		dropzoneStyle,
		dropzoneInputStyle,
		dropzoneBoxStyle,
		dropzoneImgStyle,
		component.label && dropzoneLabelStyle,
		component.description && dropzoneDescriptionStyle,
		errorContainerStyle,
		fileWrapperStyle,
		fileStyle,
		fileImgStyle,
		fileNameStyle,
		fileSizeStyleStyle,
		fileActionsStyle,
		fileRemoveStyle,
		loaderStyle,
		asteriskStyle,
	]
		.filter(Boolean)
		.join('');
}

export function createFormClasses(contentModel, generalSettings, entityId) {
	const classes = [];

	const content = generateGeneralStyles(contentModel, generalSettings, entityId);

	classes.push(content);

	if (contentModel.mobileProps.mobileChanged) {
		const mobileModel = { ...contentModel, ...contentModel.mobileProps };
		classes.push(generateQueries(generateGeneralStyles(mobileModel, generalSettings, entityId)));
	}

	if (contentModel && contentModel.rows) {
		contentModel.rows.forEach((row) => {
			classes.push(generateRowClasses(row, contentModel, generalSettings));

			classes.push(generateSlotClasses(row, contentModel).join(''));

			if (row.mobileProps.mobileChanged) {
				classes.push(generateQueries(generateRowClasses({ ...row, ...row.mobileProps }, contentModel, generalSettings)));
			}
		});
	}

	if (contentModel && contentModel.summaryPage?.active) {
		classes.push(generateSummaryPageClasses(contentModel, generalSettings));
	}

	return `
		.shared-input-field label,
		.single-select-field label
		.multi-select-field label {
            padding: 0;
            display: flex;
            width: 100%;
        }

		.shared-input-field label .form-field-label,
		.checkbox-field .form-field-label,
		.checkbox-group-field .form-field-label
		.single-select-field .form-field-label
		.multi-select-field .form-field-label
		.radio-group-field .form-field-label {
            white-space: pre-wrap;
            word-break: break-word;
        }

		.shared-input-field .form-field-label .form-field-required,
		.checkbox-field .form-field-label .form-field-required,
		.checkbox-group-field .form-field-label .form-field-required,
		.checkbox-group-container label .form-field-label .form-field-required,
		.single-select-field .form-field-label .form-field-required,
		.multi-select-field .form-field-label .form-field-required,
		.radio-group-field .form-field-label .form-field-required,
		.file-upload-field .form-field-required {
			display: none;
			text-decoration: none;
			font-weight: normal;
			font-style: normal;
			font-family: inherit;
    }

		.required-component .form-field-label .form-field-required {
			display: initial;
		}

		.shared-input-field .form-input-error-message,
		.checkbox-field .form-input-error-message,
		.checkbox-group-field .form-input-error-message,
		.single-select-field .form-input-error-message,
		.multi-select-field .form-input-error-message,
		.radio-group-field .form-input-error-message {
			display: block;
		}

		.shared-input-field .form-input-wrapper-div {
            display: flex;
            flex: 1 1 auto;
			align-items: center;
			flex-direction: column;
			border-style: solid;
        }

		.shared-input-field .form-input-element {
            width: 100%; 
            min-width: 0; 
            outline: none; 
            flex: 1;
			border: none;
			background-color: transparent;
        }

		.button-component {
			display: flex;
			flex-wrap: wrap;
		}

		.button-component .submit-button, .button-component .next-button, .button-component .back-button {
			display: flex;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			border-style: solid;
		}

		.checkbox-field label, .radio-field {
			width: 100%;
            padding: 0;
            display: flex;
			align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
		}

		.checkbox-field .form-input-wrapper-div, .radio-field .form-input-wrapper-div {
            position: relative;
		}

		.checkbox-field .form-input-element, .radio-field .form-input-element {
			opacity: 1;
			display: block;
			cursor: pointer;
            min-width: 0; 
            outline: none; 
			margin: 0;
			appearance: none;
			border-style: solid;
        }

		.checkbox-field .checkbox-check-marker {
            position: absolute;
			left: 50%;
			top: 50%;
			cursor: pointer;
			line-height: 1;
        }

		.radio-field .form-input-element::before {
			display: none;
			content: "";
            position: absolute;
			left: calc(50% + 2px);
			top: calc(50% + 2px);
			cursor: pointer;
			line-height: 1;
        }

		.radio-field .form-input-element:checked::before {
			display: block;
        }

		.radio-field .form-field-label {
			margin-left: 10px;
			display: block;
        }

		.image-component {
			align-items: center;
			display: flex;
		}

		.image-component a {
			line-height: 1px;
			display: flex;
			align-items: center;
		}

		.image-component img {
			box-sizing: border-box;
			display: block;
			height: auto;
			width: 100%;
		}

		.image-component div {
			border: 0;
			width: 100%;
		}

		.social-follow-component .social-icon-spacing {
			display: inline-block;
		}

		.social-follow-component .social-icon-div {
			display: inline-block;
			vertical-align: bottom;
		}

		.social-follow-component .social-icon-div a {
			display: block;
			cursor: pointer;
			background-size: 100%;
			background-repeat: no-repeat;
		}
	
		.social-follow-component .social-icon-div img {
			visibility: hidden;
			display: block;
		}

		@media only screen and (max-width: 700px) {
			.responsive-row .inner-row-div .border-wrapper .row-slot-wrapper{
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
					-ms-flex-direction: column;
						flex-direction: column;
			}

			.responsive-row .inner-row-div .border-wrapper .row-slot-wrapper .responsive-slot {
				max-width: initial !important;
			}
		}
		${classes.join('')}
	`;
}

export function generateGeneralStyles(contentModel, generalSettings, entityId) {
	const formTarget = `.wrapper-${entityId} .content`;

	return `
		.wrapper-${entityId} {
			background-image: url("${generateImageContentUrl(
				contentModel.background_image,
				contentModel.background_image_type,
				contentModel.background_repeat === 'full' ? contentModel.structureWidth : '',
				generalSettings.retina_images,
				config.imgix_prefix,
			)}");
			background-color: ${contentModel.bg_color || 'transparent'};
			background-repeat: ${contentModel.background_repeat === 'full' ? 'no-repeat' : contentModel.background_repeat};
			background-size: ${contentModel.background_size};
		}
		${formTarget} {
			border-radius: ${contentModel.structureBorderRadius}px;
			max-width: ${contentModel.structureWidth}px
		}
		.wrapper-${entityId} .form-page {
			border-width: ${contentModel.borderWidth}px;
			border-color: ${contentModel.borderColor};
			border-style: solid;
		}
	`;
}

export function generateRowClasses(row, contentModel, generalSettings) {
	const rowWidth = row.rowWidth ? row.rowWidth : contentModel.structureWidth;

	const display = row.hidden ? 'none' : 'block';

	const src = generateImageContentUrl(
		row.background_image,
		row.background_image_type,
		rowWidth,
		generalSettings.retina_images,
		config.imgix_prefix,
	);

	const innerRowDiv = `
		.row${row.id.replace(/-/g, '')} .inner-row-div {
			margin: 0 auto;
			width: 100%;
			vertical-align: top;
			max-width: ${rowWidth}px;
		}
	`;

	const padding = getPadding(row);

	const innerBorder = `
		.row${row.id.replace(/-/g, '')} .inner-row-div .border-wrapper {
			width: 100%;
			background-color: ${row.bg_color || 'transparent'};
			background-image: url("${src}");
			background-repeat: ${row.background_repeat === 'full' ? 'no-repeat' : row.background_repeat};
			background-position: ${row.background_position};
			background-size: ${row.background_repeat === 'full' ? '100%' : 'auto'};
			border-style: solid;
			border-width: ${row.borderWidth}px;
			border-color: ${row.borderColor};
			border-radius: ${row.borderRadius}px;
			${padding}
		}
	`;

	const rowClass = `
		.row${row.id.replace(/-/g, '')} {
			-webkit-box-ordinal-group: ${row.index + 1};
			-ms-flex-order: ${row.index};
			order: ${row.index};
			display: ${display};
			background-color: ${row.background_color_wide || 'transparent'};
			background-position: ${row.background_position_wide};
			background-repeat: ${row.background_repeat_wide === 'full' ? 'no-repeat' : row.background_repeat_wide};
			background-size: ${row.background_repeat_wide === 'full' ? '100%' : 'auto'}
		}
		${innerRowDiv}
		${innerBorder}
	`;

	return rowClass;
}

export function generateSummaryPageClasses(contentModel, generalSettings) {
	const {
		layoutStyles: {
			padding,
			multiPadding,
			borderWidth,
			borderColor,
			borderRadius,
			background_image,
			background_repeat,
			background_position,
			bg_color,
			width,
		},
		buttonStyles,
	} = contentModel.summaryPage;

	const bgSize = width || generalSettings.structureWidth;
	const innerRowDiv = `
		.summary-page .inner-row-div {
			margin: 0 auto;
			width: 100%;
			vertical-align: top;
			max-width: ${width || generalSettings.structureWidth}px;
			border-radius: ${borderRadius}px;
			background-repeat: ${background_repeat === 'full' ? 'no-repeat' : background_repeat};
			background-size: ${background_repeat === 'full' ? '100%' : (generalSettings.retina_images ? bgSize * 2 : bgSize) + 'px'};
			background-position: ${background_position};
			background-color: ${bg_color};
			border-collapse: collapse;
			${getPadding({ padding, multiPadding })};
			${borderWidth ? `border: ${borderWidth}px solid ${borderColor};` : ''}
			${background_image ? `background-image: url('${background_image}');` : ''}
		}
	`;

	const classes = `
		.summary-page {}

		.summary-page .title {
			font-size: 16px;
			font-weight: 600;
			font-family: ${generalSettings.fontFamily};
			margin-bottom: 20px;
			color: rgba(0, 0, 0, 0.94);
		}

		.summary-page .page-marker {
      font-family: ${generalSettings.fontFamily};
			font-size: 13px;
			border-top: 1px solid rgba(0,0,0,0.11);
			display: flex;
			justify-content: end;
			align-items: center;
			padding: 10px 0;
			color: rgba(0,0,0,0.68);
    }
			
		.summary-page .summary-page-collapse-button .collapse-icon {
			margin-left: 2px;
			transition: transform 0.2s;
			transform-origin: center center;
			display: inline-block;
		}

		.summary-page .summary-page-collapse-button button {
			background: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		.summary-page .page-marker .mdi:before {
			font-size: 18px;
			color: rgba(0,0,0,0.68);
			padding: 3px;
		}

		.summary-page .summary-page-edit-page {
			margin-left: 12px;
			border-radius: 50%;
			background: none;
			border: 0;
			padding: 0;
		}

		.summary-page .summary-page-edit-page > i {
			pointer-events: none;
		}

		.summary-page .summary-page-collapse-button,
		.summary-page .summary-page-edit-page {
			cursor: pointer;
			transition: background-color 200ms;
		}

		.summary-page .summary-page-collapse-button:hover:not(:has(.summary-page-edit-page:hover)),
		.summary-page .summary-page-edit-page:hover {
			background-color: rgba(0,0,0,0.06);
		}

		${generateButtonClass({ id: 'summary-page-submit', ...buttonStyles }, width, contentModel, 0)}
		${generateSummaryPageClass(contentModel.summaryPage)}
		${innerRowDiv}
	`;

	return classes;
}

export function generateSlotClasses(row, contentModel) {
	const slotClasses = [];

	let spacerIndex = 3;
	let spacerOrder = 2;

	for (let i = 0; i < row.slots.length; i++) {
		const slotId = row.slots[i].id;

		let componentClasses = [];

		const deductPadding =
			row.multiPadding && row.multiPadding.allow ? row.multiPadding.paddingRight + row.multiPadding.paddingLeft : row.padding * 2;

		let slotWidth =
			(row.rowWidth ? row.rowWidth : contentModel.structureWidth) / slotSubstractor[row.slots[i].type] -
			deductPadding / slotSubstractor[row.slots[i].type] -
			(row.borderWidth * 2) / slotSubstractor[row.slots[i].type];

		if (row.slots[i].components && row.slots[i].components.length) {
			const deductPaddingForComp =
				row.slots[i].multiPadding && row.slots[i].multiPadding.allow
					? row.slots[i].multiPadding.paddingRight + row.slots[i].multiPadding.paddingLeft
					: row.slots[i].padding * 2;
			const slotWidthForComp = slotWidth - deductPaddingForComp - row.slots[i].borderWidth * 2 || 0;

			componentClasses = generateComponentClasses(row.slots[i], slotWidthForComp, contentModel, row.pageIndex);
		}

		const alignment = getDesignerAlignment(row.slots[i].vertical_align);

		const slotBorder = row.slots[i].borderWidth ? `border: ${row.slots[i].borderWidth}px solid ${row.slots[i].borderColor};` : '';

		const slotClass = `
            .slot${slotId.replace(/-/g, '')} {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                ${getPadding(row.slots[i])}
                background-color: ${row.slots[i].background_color || 'transparent'};
                min-height: ${row.slots[i].minHeight}px;
                max-width: ${slotWidth}px;
            }

            .slot${slotId.replace(/-/g, '')} .slot-container {
                background-color: ${row.slots[i].contentBgColor || 'transparent'};
                ${slotBorder}
                overflow: visible;
                height: 100%;
                -webkit-box-pack: ${alignment.replace('flex-', '')};
                -ms-flex-pack: ${alignment.replace('flex-', '')};
                justify-content: ${alignment};
                border-radius: ${row.slots[i].borderRadius}px;
            }
            
            ${componentClasses.join('')}
        `;

		slotClasses.push(slotClass);

		if (row.slots[i].mobileProps.mobileChanged) {
			const mobileRow = { ...row, ...row.mobileProps };
			const mobileSlot = { ...row.slots[i], ...row.slots[i].mobileProps };

			const mobileAlignment = getDesignerAlignment(mobileSlot.vertical_align);

			const mobileSlotBorder = mobileSlot.borderWidth ? `border: ${mobileSlot.borderWidth}px solid ${mobileSlot.borderColor};` : '';

			let display =
				mobileRow.responsive && !mobileSlot.components.length
					? `display: none !important;`
					: `display: -webkit-box !important;
					display: -ms-flexbox !important;
					display: flex !important;`;

			const mobileSlotClass = `
            .slot${slotId.replace(/-/g, '')} {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                ${display}
                ${getPadding(mobileSlot)}
                background-color: ${mobileSlot.background_color || 'transparent'};
                min-height: ${mobileSlot.minHeight}px;
            }

            .slot${slotId.replace(/-/g, '')} .slot-container {
                background-color: ${mobileSlot.contentBgColor || 'transparent'};
                ${mobileSlotBorder}
                overflow: visible;
                height: 100%;
                -webkit-box-pack: ${mobileAlignment.replace('flex-', '')};
                -ms-flex-pack: ${mobileAlignment.replace('flex-', '')};
                justify-content: ${mobileAlignment};
                border-radius: ${mobileSlot.borderRadius}px;
            }
			`;

			slotClasses.push(generateQueries(mobileSlotClass));
		}

		spacerIndex = spacerIndex + 2;
		spacerOrder = spacerOrder + 2;
	}

	return slotClasses;
}

export function generateComponentClasses(slot, slotWidth, contentModel, pageIndex) {
	const componentClasses = [];

	if (slot.components) {
		slot.components.forEach((component) => {
			switch (component.type) {
				case AllComponentTypes.text:
					componentClasses.push(generateTextClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateTextQuery(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.spacer:
					componentClasses.push(generateSpacerClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSpacerClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.submit_button:
					componentClasses.push(generateButtonClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateButtonClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.social_follow:
					componentClasses.push(generateFollowClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateFollowClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.image:
					componentClasses.push(generateImageClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateImageClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.email_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.text_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.longtext_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.number_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.phone_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.date_input:
					componentClasses.push(generateSharedInputClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSharedInputClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.radio:
					componentClasses.push(generateRadioClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateRadioClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.checkbox:
					componentClasses.push(generateCheckboxClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateCheckboxClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.checkbox_group:
					componentClasses.push(generateCheckboxGroupClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateCheckboxGroupClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.singleselect_input:
					componentClasses.push(generateSingleSelectClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateSingleSelectClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.multiselect_input:
					componentClasses.push(generateMultiSelectClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateMultiSelectClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.gdpr:
					componentClasses.push(generateGDPRboxClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateGDPRboxClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.recaptcha:
					componentClasses.push(generateRecaptchaClass(component, slotWidth, contentModel, pageIndex));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateRecaptchaClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				case AllComponentTypes.file_upload:
					componentClasses.push(generateFileUploadClass(component));
					if (component.mobileProps.mobileChanged) {
						componentClasses.push(
							generateQueries(
								generateFileUploadClass(
									{ ...component, ...component.mobileProps },
									slotWidth,
									{ ...contentModel, ...contentModel.mobileProps },
									pageIndex,
								),
							),
						);
					}
					break;

				default:
					break;
			}
		});
	}
	return componentClasses;
}

export function generateTextClass(component, slotWidth, contentModel) {
	const padding = getPadding(component);

	return `
        .text-desktop.text${component.id.replace(/-/g, '')} {
            ${padding}
            display: block;
            border-width: ${component.textBorderWidth}px;
            border-color: ${component.textBorderColor};
            border-radius: ${component.textBorderRadius}px;
            border-style: solid;
            background-color: ${component.background_color || 'transparent'};
            font-family: ${contentModel.font_family};
            line-height: ${contentModel.text_line_height};
            color: ${contentModel.color};
            font-size: ${contentModel.font_size / 16}rem;
            word-wrap: break-word;
            word-break: break-word;
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }

        .text-mobile.text${component.id.replace(/-/g, '')} {
            display: none;
        }
    `;
}

export function generateTextQuery(component, slotWidth, mobileView) {
	const padding = getPadding(component);

	return `
        .text-desktop.text${component.id.replace(/-/g, '')} {
            display: none;
        }

        .text-mobile.text${component.id.replace(/-/g, '')} {
            ${padding}
            display: block;
            border-width: ${component.textBorderWidth}px;
            border-color: ${component.textBorderColor};
            border-radius: ${component.textBorderRadius}px;
            border-style: solid;
            background-color: ${component.background_color || 'transparent'};
            font-family: ${mobileView.font_family};
            line-height: ${mobileView.text_line_height};
            color: ${mobileView.color};
            font-size: ${mobileView.font_size / 16}rem;
            word-wrap: break-word;
            word-break: break-word;
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }
    `;
}

export const generateQueries = (classes) => {
	return `
	@media only screen and (max-width: 700px) {

        .responsive-row .inner-row-div .border-wrapper .row-slot-wrapper{
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }

        .responsive-row .inner-row-div .border-wrapper .row-slot-wrapper .responsive-slot {
            max-width: initial !important;
        }

        ${classes}

    }
	`;
};
